import actions from "./actions";

const initState = {
    loading: false,
    networkError: null,
    networkResult: [],
    networkMessage: null,
    buttonLoading: false,
    networkFormModal: false,
    dealerResult: [],
    dealerError: null,
    dealerMessage: null,
    shortCodeError: null,
    shortCodeResult: [],
    activationRequestResult: [],
    lockLoading: false,
    Lockmessage: null,
    LockedFailedmessage: null,
    unLockMessage: null,
    activationListLoading: false,
    activationError: null,
    transectionIdModal: false,
    activationMessage: null,
    confirmationModal: false,
    requestData: [],
    modalViewLoader: false,
    cancelModal: false,
    messageModal: null,
    apiErrorModal: null,
    completeModal: false,
};

export default function reducer(state = initState, action) {
    let activationListNewState;
    switch (action.type) {
        case actions.GET_NETWORK_BY_AGGR_ID:
            return {
                ...state,
                loading: true,
                networkError: null,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_NETWORK_BY_AGGR_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_NETWORK_BY_AGGR_ID_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };

        case actions.GET_DEALER:
            return {
                ...state,
                loading: true,
                dealerError: null,
                dealerMessage: null,
            };
        case actions.GET_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: null,
                dealerResult: action.dealerResult,
            };
        case actions.GET_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                dealerError: action.dealerError,
                dealerMessage: null,
            };
        case actions.GET_SHORT_CODE:
            return {
                ...state,
                shortCodeError: null,
            };
        case actions.GET_SHORT_CODE_SUCCESS:
            return {
                ...state,
                shortCodeResult: action.shortCodeResult
            };
        case actions.GET_SHORT_CODE_FAILED:
            return {
                ...state,
                shortCodeError: action.error
            };

        case actions.GET_ACTIVATION_REQUEST_LIST:
            return {
                ...state,
                activationListLoading: true,
                activationRequestResult: []
            };
        case actions.GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL:
            return {
                ...state,
                activationListLoading: false,
                activationRequestResult: action.payload.activationRequestResult
            };
        case actions.GET_ACTIVATION_REQUEST_LIST_FAILED:
            return {
                ...state,
                activationError: action.payload.activationError,
                activationListLoading: false,
            };

        case actions.OPEN_TRANSACTION_ID_MODAL:
            return {
                ...state,
                transectionIdModal: true,
                activationMessage: null,
                activationError: null,
            }
        case actions.CLOSE_TRANSACTION_ID_MODAL:
            return {
                ...state,
                transectionIdModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false
            }
        case actions.ACCESS_TRANSACTION_MODAL:
            return {
                ...state,
                requestData: null,
                modalViewLoader: true,
            }

        case actions.ACCESS_TRANSACTION_MODAL_SUCCESS:
            return {
                ...state,
                requestData: action.payload.requestData,
                modalViewLoader: false
            }

        case actions.LOCK_ACTIVATION:
            return {
                ...state,
                buttonLoading: false,
                lockLoading: true,
            }
        case actions.LOCK_ACTIVATION_RESULT:
            return {
                ...state,
                Lockmessage: action.payload.Message,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false,
                lockLoading: false,
            }
        case actions.LOCK_ACTIVATION_FAILED_RESULT:
            return {
                ...state,
                LockFailAlert: true,
                LockedFailedmessage: action.payload.Message,
                LockedByUser: action.payload.LockedByUser,
                buttonLoading: false,
                lockLoading: false,
            }

        case actions.UNLOCK_ACTIVATION:
            activationListNewState = state.activationRequestResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.unLockData.activationId) {
                    temp.releaseLoading = !temp.releaseLoading;
                }
                return temp;
            });
            return {
                ...state,
                activationRequestResult: activationListNewState
            }
        case actions.UNLOCK_ACTIVATION_SUCCESS:
            return {
                ...state,
                unLockMessage: action.payload,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false
            }

        case actions.PREPARE_CANCEL_MODEL:
            return {
                ...state,
                activationMessage: null,
                activationError: null,
                cancelModal: true
            }
        case actions.CLOSE_CANCEL_MODAL:
            return {
                ...state,
                cancelModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false,
                completeModal: false,
            }
        case actions.GET_ERROR_CODE:
            return {
                ...state,
                cancelModal: true,
            }
        case actions.GET_ERROR_CODE_SUCCESS:
            return {
                ...state,
                errorCodeResult: action.payload.errorCodeResult,
                cancelModal: true,
            }
        case actions.CANCEL_ACTIVATION:
            return {
                ...state,
                buttonLoading: true,
                activationMessage: null,
                messageModal: null,
                activationError: null
            }
        case actions.CANCEL_ACTIVATION_SUCCESSFUL:
            return {
                ...state,
                messageModal: action.payload.message,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false,
                cancelModal: false
            }
        case actions.DATA_SUBMIT_FAILED:
            return {
                ...state,
                loading: false,
                apiErrorModal: action.payload.error,
                buttonLoading: false,
                cancelModal: false
            }

        case actions.PREPARE_COMPLETE_MODAL:
            return {
                ...state,
                completeModal: true,
                activationMessage: null,
                activationError: null,
            }
        case actions.CLOSE_COMPLETE_MODAL:
            return {
                ...state,
                completeModal: false,
                activationMessage: null,
                activationError: null,
                confirmationModal: false
            }

        case actions.COMPELETE_ACTIVATION:
            return {
                ...state,
                buttonLoading: true
            }
        case actions.COMPELETE_ACTIVATION_SUCCESSFULL:
            return {
                ...state,
                messageModal: action.payload.message,
                loading: false,
                apiErrorModal: null,
                activationError: null,
                activationRequestResult: action.payload.activationRequestResult,
                buttonLoading: false,
                completeModal: false,
            }

        case actions.RESET_ACTIVATION:
            return {
                ...state,
                networkError: null,
                networkMessage: null,
                dealerError: null,
                dealerMessage: null,
                shortCodeError: null,
                Lockmessage: null,
                LockedFailedmessage: null,
                unLockMessage: null,
                activationError: null,
                activationMessage: null,
                apiErrorModal: null,
                messageModal: null,
            }
        default:
            return state;
    }
}