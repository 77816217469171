const actions = {
    GET_NETWORK_BY_AGGR_ID: 'GET_NETWORK_BY_AGGR_ID',
    GET_NETWORK_BY_AGGR_ID_SUCCESS: 'GET_NETWORK_BY_AGGR_ID_SUCCESS',
    GET_NETWORK_BY_AGGR_ID_FAILED: ' GET_NETWORK_BY_AGGR_ID_FAILED',
    GET_DEALER: 'GET_DEALER',
    GET_DEALER_SUCCESS: 'GET_DEALER_SUCCESS',
    GET_DEALER_FAILED: 'GET_DEALER_FAILED',
    GET_SHORT_CODE: 'GET_SHORT_CODE',
    GET_SHORT_CODE_SUCCESS: 'GET_SHORT_CODE_SUCCESS',
    GET_SHORT_CODE_FAILED: "GET_SHORT_CODE_FAILED",
    GET_ACTIVATION_REQUEST_LIST: "GET_ACTIVATION_REQUEST_LIST",
    GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL: " GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL",
    GET_ACTIVATION_REQUEST_LIST_FAILED: " GET_ACTIVATION_REQUEST_LIST_FAILED",
    OPEN_TRANSACTION_ID_MODAL: "OPEN_TRANSACTION_ID_MODAL",
    CLOSE_TRANSACTION_ID_MODAL: "CLOSE_TRANSACTION_ID_MODAL",
    ACCESS_TRANSACTION_MODAL: "ACCESS_TRANSACTION_MODAL",
    ACCESS_TRANSACTION_MODAL_SUCCESS: "ACCESS_TRANSACTION_MODAL_SUCCESS",
    LOCK_ACTIVATION: "LOCK_ACTIVATION",
    LOCK_ACTIVATION_RESULT: "LOCK_ACTIVATION_RESULT",
    LOCK_ACTIVATION_FAILED_RESULT: "LOCK_ACTIVATION_FAILED_RESULT",
    UNLOCK_ACTIVATION: "UNLOCK_ACTIVATION",
    UNLOCK_ACTIVATION_SUCCESS: "UNLOCK_ACTIVATION_SUCCESS",
    RESET_ACTIVATION: "RESET_ACTIVATION",
    PREPARE_CANCEL_MODEL: "PREPARE_CANCEL_MODEL",
    CLOSE_CANCEL_MODAL: "CLOSE_CANCEL_MODAL",
    GET_ERROR_CODE: "GET_ERROR_CODE",
    GET_ERROR_CODE_SUCCESS: "GET_ERROR_CODE_SUCCESS",
    CANCEL_ACTIVATION: "CANCEL_ACTIVATION",
    CANCEL_ACTIVATION_SUCCESSFUL: "CANCEL_aCTIVATION_SUCCESSFUL",
    DATA_SUBMIT_FAILED: "DATA_SUBMIT_FAILED",
    PREPARE_COMPLETE_MODAL: "PREPARE_COMPLETE_MODAL",
    CLOSE_COMPLETE_MODAL: "CLOSE_COMPLETE_MODAL",
    COMPELETE_ACTIVATION: "COMPELETE_ACTIVATION",
    COMPELETE_ACTIVATION_SUCCESSFULL: "COMPELETE_ACTIVATION_SUCCESSFULL",


    resetActivation: () => ({
        type: actions.RESET_ACTIVATION,
    }),
    getNetworkListByAggrID: (aggID) => ({
        type: actions.GET_NETWORK_BY_AGGR_ID,
        payload: { aggID }
    }),
    getNetworkListByAggrIDSuccess: (networkResult) => ({
        type: actions.GET_NETWORK_BY_AGGR_ID_SUCCESS,
        networkResult
    }),
    getNetworkListByAggrIDFailed: (networkError) => ({
        type: actions.GET_NETWORK_BY_AGGR_ID_FAILED,
        networkError
    }),

    getDealer: () => ({
        type: actions.GET_DEALER,
    }),
    getDealerSuccess: (dealerResult) => ({
        type: actions.GET_DEALER_SUCCESS,
        dealerResult
    }),
    getDealerFailed: (dealerError) => ({
        type: actions.GET_DEALER_FAILED,
        dealerError
    }),
    getShortCode: (data) => ({
        type: actions.GET_SHORT_CODE,
        payload: { data }
    }),
    getShortCodeSuccess: (shortCodeResult) => ({
        type: actions.GET_SHORT_CODE_SUCCESS,
        shortCodeResult
    }),
    getShortCodeFailed: (error) => ({
        type: actions.GET_SHORT_CODE_FAILED,
        error
    }),

    getActivationRequestList: (searchData,aggregatorID) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST,
        payload: { searchData ,aggregatorID}
    }),
    getActivationRequestListSuccessful: (activationRequestResult) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST_SUCCESSFUL,
        payload: { activationRequestResult }
    }),
    getActivationRequestListFailed: (activationError) => ({
        type: actions.GET_ACTIVATION_REQUEST_LIST_FAILED,
        payload: { activationError }
    }),

    transactionIdModal: () => ({
        type: actions.OPEN_TRANSACTION_ID_MODAL,
    }),
    closeTransactionIdModal: () => ({
        type: actions.CLOSE_TRANSACTION_ID_MODAL
    }),

    accessTransactionModal: (id) => ({
        type: actions.ACCESS_TRANSACTION_MODAL,
        payload: { id }
    }),
    accessTransactionModalSuccess: (requestData) => ({
        type: actions.ACCESS_TRANSACTION_MODAL_SUCCESS,
        payload: { requestData }
    }),

    lockActivation: (lockActivationData,aggregatorID, searchData) => ({
        type: actions.LOCK_ACTIVATION,
        payload: { lockActivationData,aggregatorID, searchData }
    }),
    lockActivationResultData: (lockData, activationRequestResult) => ({
        type: actions.LOCK_ACTIVATION_RESULT,
        payload: { lockData, activationRequestResult }
    }),
    lockActivationFailedResult: (lockData) => ({
        type: actions.LOCK_ACTIVATION_FAILED_RESULT,
        payload: lockData
    }),

    unLockActivation: (unLockData,aggregatorID, searchData) => ({
        type: actions.UNLOCK_ACTIVATION,
        payload: { unLockData,aggregatorID, searchData }
    }),
    unLockActivationSuccess: (unLockData, activationRequestResult) => ({
        type: actions.UNLOCK_ACTIVATION_SUCCESS,
        payload: { unLockData, activationRequestResult }
    }),

    prepareCancelModal: () => ({
        type: actions.PREPARE_CANCEL_MODEL
    }),
    closeCancelModal: () => ({
        type: actions.CLOSE_CANCEL_MODAL
    }),
    getErrorCode: () => ({
        type: actions.GET_ERROR_CODE,
    }),
    getErrorCodeSuccess: (errorCodeResult) => ({
        type: actions.GET_ERROR_CODE_SUCCESS,
        payload: { errorCodeResult }
    }),
    cancelActivation: (cancelData, id,aggregatorID, searchData) => ({
        type: actions.CANCEL_ACTIVATION,
        payload: { cancelData, id,aggregatorID, searchData }
    }),
    cancelActivationSuccessful: (activationRequestResult, message) => ({
        type: actions.CANCEL_ACTIVATION_SUCCESSFUL,
        payload: { activationRequestResult, message }
    }),
    dataSubmittedFail: (error) => ({
        type: actions.DATA_SUBMIT_FAILED,
        payload: { error }
    }),
    prepareCompleteModal: () => ({
        type: actions.PREPARE_COMPLETE_MODAL,
    }),
    closeCompleteModal: () => ({
        type: actions.CLOSE_COMPLETE_MODAL
    }),
    compeleteActivation: (infoData, id,aggregatorID, searchData) => ({
        type: actions.COMPELETE_ACTIVATION,
        payload: { infoData, id,aggregatorID, searchData }
    }),
    compeleteActivationSuccessfull: (activationRequestResult, message) => ({
        type: actions.COMPELETE_ACTIVATION_SUCCESSFULL,
        payload: { activationRequestResult, message }
    }),



}
export default actions;