import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './Sidebar.css';
import AuthActions from '../../redux/auth/actions'
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin, isDealer, isAdminAgent, allowDealerCreation, isAdminOrAgent } from '../../helpers/commonFunction';
import Logo from '../../../src/assets/images/Long.png'
const { getUserDataByToken } = AuthActions;
function Sidebar() {
    const dispatch = useDispatch();
    const tokenData = useSelector((state) => state.auth.tokenData);
    useEffect(() => {
        dispatch(getUserDataByToken());
    }, [dispatch]);

    return (
        <div className="iq-sidebar" >
            <div className="iq-sidebar-logo d-flex justify-content-between">
                <a href="/">
                    <img src={Logo} alt="Logo" style={{ height: "85px" }} />
                </a>
            </div>
            <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">

                        {true && <li className='shouldActive'> <NavLink to="/" className="iq-waves-effect"><i className="ri-home-4-line"></i><span>Dashboard</span></NavLink> </li>}

                        {/* {(isAdmin(tokenData) || isDealer(tokenData)) && <li><a href="#admin" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-admin-line"></i><span>Admin</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        </li>}
                        {(isAdmin(tokenData) || isDealer(tokenData) || isAdminAgent(tokenData)) && <li><a href="#config" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-list-settings-line"></i><span>Config</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        </li>} */}
                        <li><a href="" className='iq-waves-effect'><i class="ri-building-3-line"></i><span>Activations</span></a></li>
                        <div className='pl-3 fs-14'>{true && <li className='shouldActive'><NavLink to="/activation-request"><i className="ri-font-color"></i>Activation Request</NavLink></li>}</div>
                        {/* <li><a href="#report" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-file-chart-line"></i><span>Reports</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        </li> */}
                    </ul>
                </nav>
                <div className="p-3"></div>
            </div>
        </div>
    )
}

export default Sidebar