import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData } from '../../helpers/fackBackend_Helper';

function* getNetworkListByAggrIDResponse({ payload: { aggID } }) {
    try {
        const response = yield call(getList, '/getNetworkByAggrID/' + aggID);
        if (response.response.Status) {
            yield put(actions.getNetworkListByAggrIDSuccess(response.response.Data));
        } else {
            yield put(actions.getNetworkListByAggrIDFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworkListByAggrIDFailed(error));
    }
}

function* getDealerListResponse() {
    try {
        const response = yield call(getList, '/agg_dealer');
        if (response.response.Status) {
            yield put(
                actions.getDealerSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* getShortCodeDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/agg_shortCode', data);
        if (response.response.Status) {
            yield put(
                actions.getShortCodeSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getShortCodeFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getShortCodeFailed(error));
    }
}

function* getActivationRequestResponse({ payload: { searchData, aggregatorID } }) {
    try {
        const response = yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);
        if (response.Status) {
            yield put(
                actions.getActivationRequestListSuccessful(
                    response.activations
                ));
        } else {

            yield put(actions.getActivationRequestListFailed(response.Message));
        }
    } catch (error) {

        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getAccessTransactionModal({ payload: { id } }) {
    try {
        const response = yield call(getList, '/agg_activationRequestInfo/' + id);
        if (response.Status) {
            yield put(actions.accessTransactionModalSuccess(response.requestData));
        } else {
            yield put(actions.getActivationRequestListFailed(response));
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getLockActivation({ payload: { lockActivationData, aggregatorID, searchData } }) {
    try {
        const response = yield call(addData, '/agg_lockActivation', lockActivationData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);
            if (responseList.Status) {
                yield put(actions.lockActivationResultData(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(actions.lockActivationFailedResult(response.lockData[0]));
            yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);

        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getUnlockActivation({ payload: { unLockData, aggregatorID, searchData } }) {
    try {
        const response = yield call(addData, '/agg_releaseLockActivation/', unLockData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);
            if (responseList.Status) {
                yield put(actions.unLockActivationSuccess(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(actions.lockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error));
    }
}

function* getErrorCode() {
    try {
        const response = yield call(getList, '/agg_showOnPortalErrorCodes')
        if (response.Status) {
            yield put(actions.getErrorCodeSuccess(response.showOnPortalErrorCodes))
        } else {
            yield put(actions.getActivationRequestListFailed(response.response.Message))
        }
    } catch (error) {
        yield put(actions.getActivationRequestListFailed(error))
    }
}

function* getCancelData({ payload: { cancelData, id,aggregatorID, searchData } }) {
    try {
        const response = yield call(editData, '/agg_updateRemark/' + id, cancelData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);
            if (responseList.Status) {
                yield put(actions.cancelActivationSuccessful(responseList.activations, response.response.Message))
            } else {
                yield put(actions.dataSubmittedFail(response.response.Message));
            }
        } else {
            yield put(actions.dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dataSubmittedFail(error));
    }
}

function* getCompeleteData({ payload: { infoData, id,aggregatorID, searchData } }) {
    debugger
    try {
        const response = yield call(editData, '/agg_updateRequestForComplete/' + id, infoData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/agg_activationRequestList/' + aggregatorID, searchData);
            if (responseList.Status) {
                yield put(actions.compeleteActivationSuccessfull(responseList.activations, response.response.Message))
            } else {
                yield put(actions.dataSubmittedFail(response.response.Message));
            }
        } else {
            yield put(actions.dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dataSubmittedFail(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NETWORK_BY_AGGR_ID, getNetworkListByAggrIDResponse)]);
    yield all([takeEvery(actions.GET_DEALER, getDealerListResponse)]);
    yield all([takeEvery(actions.GET_SHORT_CODE, getShortCodeDataResponse)]);
    yield all([takeEvery(actions.GET_ACTIVATION_REQUEST_LIST, getActivationRequestResponse)]);
    yield all([takeEvery(actions.ACCESS_TRANSACTION_MODAL, getAccessTransactionModal)]);
    yield all([takeEvery(actions.LOCK_ACTIVATION, getLockActivation)]);
    yield all([takeEvery(actions.UNLOCK_ACTIVATION, getUnlockActivation)]);
    yield all([takeEvery(actions.GET_ERROR_CODE, getErrorCode)]);
    yield all([takeEvery(actions.CANCEL_ACTIVATION, getCancelData)]);
    yield all([takeEvery(actions.COMPELETE_ACTIVATION, getCompeleteData)]);

}