import actions from "./actions";

const initState = {
    profileListResult: [],
    loading: false,
    profileError: null,
    profileMessage: null,
    prepareEditFormModel: false,
    buttonLoading: false,
    editProfileModel: false,

    changePasswordPortalModal: false,
    changePasswordPortalLoading: false,
    changePasswordPortalMessage: null,
    changePasswordPortalError: null,
    openChangePasswordModel: false,
    handleChangePasswordForm:false
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_POFILE_DATA_LIST:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: false,
            };
        case actions.GET_POFILE_DATA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileMessage: null,
                profileListResult: action.profileListResult,
                buttonLoading: false,
            };
        case actions.GET_POFILE_DATA_LIST_FAILED:
            return {
                ...state,
                loading: false,
                profileError: action.profileError,
                profileMessage: null,
                buttonLoading: false,

            };
        case actions.EDIT_PROFILE_LIST:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: true,
            };
        case actions.EDIT_PROFILE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileListResult: action.profileListResult,
                prepareEditFormModel: false,
                profileMessage: action.profileMessage,
                buttonLoading: false,
            };
        case actions.EDIT_PROFILE_LIST_FAILED:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: action.profileError,
                buttonLoading: false,
            };
        case actions.RESET_PROFILE:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: null,
                buttonLoading: false,
                prepareEditFormModel: false,
            };
        case actions.PREPARE_EDIT_FORM_MODEL:
            return {
                ...state,
                prepareEditFormModel: true,
            };
        case actions.RESET_PROFILE_MESSAGE:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: null,
                buttonLoading: false,
            };
        case actions.OPEN_EDIT_PROFILE_MODEL:
            return {
                ...state,
                editProfileModel: true,
            };
        case actions.CLOSE_EDIT_PROFILE_MODEL:
            return {
                ...state,
                editProfileModel: false,
            };

        case actions.OPEN_CHANGE_PASSWORD_MODEL:
            return {
                ...state,
                openChangePasswordModel: true,
            };
        case actions.CLOSE_CHANGE_PASSWORD_MODEL:
            return {
                ...state,
                openChangePasswordModel: false,
                changePasswordPortalError: null,
            };

        case actions.CHANGE_PASSWORD_PORTAL:
            return {
                ...state,
                changePasswordPortalLoading: true,
                changePasswordPortalMessage: null,
                changePasswordPortalError: null,
            };
        case actions.CHANGE_PASSWORD_PORTAL_SUCCESS:
            return {
                ...state,
                changePasswordPortalLoading: false,
                changePasswordPortalMessage: action.payload.message,
                changePasswordPortalError: null,
                openChangePasswordModel: false,
                changePasswordPortalSuccessModal: true,
                handleChangePasswordForm:true,
            };
        case actions.CHANGE_PASSWORD_PORTAL_FAILED:
            return {
                ...state,
                changePasswordPortalLoading: false,
                changePasswordPortalMessage: null,
                changePasswordPortalError: action.payload.error,
            };
        case actions.CHANGE_PASSWORD_PORTAL_SUCCESS_MODAL_CLOSE:
            return {
                ...state,
                changePasswordPortalSuccessModal: false,
            };
        case actions.CLOSE_ALERT:
            return {
                ...state,
                changePasswordPortalError: null,
            };

        default:
            return state;
    }
}
