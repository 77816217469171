import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import AuthActions from './../../redux/auth/actions'
import countryAction from './../../redux/country/actions'
import profileAction from './../../redux/profile/actions'
import authAction from './../../redux/auth/actions';
import Modal from './Model';
import Button from './Button';
import Alert from './Alert';
import { PASSWORD_VALIDATE } from '../../helpers/constant';


const { openChangePasswordModal, getUserDetails } = AuthActions;
const { getCountry } = countryAction;
const { getUserDataByToken } = authAction;
const { getProfileDataList, closeAlert, prepareEditFormModel, closeEditProfileModel, changePasswordPortal, resetProfile, changePasswordPortalSuccessModalClose, editProfileList, closeChangePasswordModel } = profileAction;

const Style = {
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}


function Profile() {
    const dispatch = useDispatch();
    const ProfileState = useSelector((state) => state.profile);
    const buttonLoading=useSelector((state) => state.profile.buttonLoading)
    const openEditModel = useSelector((state) => state.profile.editProfileModel);
    const openChangePasswordModel = useSelector((state) => state.profile.openChangePasswordModel);
    const AuthState = useSelector((state) => state.auth);
    const CountryState = useSelector((state) => state.country);
    const tokenData = useSelector((state) => state.auth.tokenData);
    const userDetails = AuthState.userDetails && AuthState.userDetails.length > 0 ? AuthState.userDetails[0] : null;
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const [accountBalance, setAccountBalance] = React.useState('0.00');
    const [editedData, setEditedData] = useState({
        name: '',
        contactEmailID: '',
        contactNumber: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        countryID: 1
    });

    const password = watch('newPassword', '');
    const confirmPassword = watch('confirmPassword', '');
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);


    const {
        register: registerEditProfile,
        handleSubmit: handleSubmitEditProfile,
        formState: {
            errors: errorsEditProfile
        },
        reset: resetEditProfile,
        watch: watchEditProfile
    } = useForm({
        defaultValues: editedData
    });

    useEffect(() => {
        dispatch(getCountry());
        // dispatch(getProfileDataList());
        // dispatch(getUserDetails());
        // dispatch(getUserDataByToken());
    }, [dispatch]);



    useEffect(() => {
        if (tokenData && tokenData !== undefined && tokenData.length > 0) {
            const balance = tokenData[0].AccountBalance !== null ? tokenData[0].AccountBalance : 0.00;
            const newBalance = balance; // .toFixed(2)
            setAccountBalance(newBalance);
        }
    }, [tokenData]);


    useEffect(() => {
        if (ProfileState.profileListResult.length > 0) {
            setEditedData({
                name: ProfileState.profileListResult[0].Name,
                contactEmailID: ProfileState.profileListResult[0].ContactEmailID,
                contactNumber: ProfileState.profileListResult[0].ContactNumber,
                address: ProfileState.profileListResult[0].Address,
                city: ProfileState.profileListResult[0].City,
                state: ProfileState.profileListResult[0].State,
                zipCode: ProfileState.profileListResult[0].ZipCode,
                countryID: ProfileState.profileListResult[0].CountryID,
            });
        }
    }, [ProfileState.profileListResult]);

    useEffect(() => {
        resetEditProfile(editedData);
    }, [editedData]);

    useEffect(() => {
        if (ProfileState.handleChangePasswordForm) {
            reset()
        };
    }, [ProfileState.handleChangePasswordForm]);



    const logout = () => {
        localStorage.clear();
        window.location.href = '/sign-in';
    }


    const handleChangePasswordModal = () => {
        reset();
        dispatch(openChangePasswordModal());
    }

    const handleCloseChangePasswordModal = () => {
        setOldPasswordShow(false);
        setNewPasswordShow(false);
        setConfirmPasswordShow(false);
        dispatch(closeChangePasswordModel());
        dispatch(closeAlert());
        reset()
    }

    const handlePasswordChange = (data) => {   
        dispatch(changePasswordPortal(data));
    }


    const handleEditProfileModal = () => {
        resetEditProfile(editedData);
        dispatch(prepareEditFormModel());
    }

    const handleCloseEditProfileModal = () => {
        dispatch(closeEditProfileModel());
        dispatch(closeAlert());
    }

    const handleEditProfile = (data) => {
        dispatch(editProfileList(userDetails.ID, data));
        dispatch(closeEditProfileModel())
    }

    const handleSuccessClose = () => {
        dispatch(changePasswordPortalSuccessModalClose());
        dispatch(resetProfile());
    }

    const alertCancel = () => {
        dispatch(closeAlert());
    }


    return (
        <>
            {/* edit profile success */}
            <Modal
                showModal={ProfileState.profileMessage}
                title="Success"
                onHide={handleSuccessClose}
                onSaveChanges={handleSuccessClose}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{ProfileState.profileMessage}</p>
                </div>

            </Modal>

            {/* edit profile */}
            <Modal
                showModal={openEditModel}
                title={'EDIT PROFILE'}
                onHide={() => handleCloseEditProfileModal()}
                width={700}
                showFooter={false}
            >
                {ProfileState.changePasswordPortalError ? <Alert type="alert-danger" text={ProfileState.changePasswordPortalError} closeBtn={alertCancel} /> : ''}

                <form onSubmit={handleSubmitEditProfile(handleEditProfile)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Name</label>
                            <input type="text" placeholder="Name" className="form-control" name="name" {...registerEditProfile("name", {
                                required: true,
                            })} />
                            {errorsEditProfile.name?.type === "required" && (
                                <div className="invalid-feedback">Name is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>E-Mail</label>
                            <input type="text" placeholder="E-Mail" className="form-control" name="contactEmailID" {...registerEditProfile("contactEmailID", {
                                required: true,
                                pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
                            })} />
                            {errorsEditProfile.contactEmailID?.type === "required" && (<div className="invalid-feedback">E-Mail is required !</div>)}
                            {errorsEditProfile.contactEmailID && errorsEditProfile.contactEmailID.type === "pattern" != '' ? <div className="invalid-feedback">Email is not valid !</div> : ''}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Phone Number</label>
                            <input type="text" placeholder="Phone Number" className="form-control" name="contactNumber" {...registerEditProfile("contactNumber", {
                                required: true,
                                pattern: /\d{10}/,
                                maxLength: 10
                            })} />
                            {errorsEditProfile.contactNumber?.type === "required" && (<div className="invalid-feedback">Phone Number is required !</div>)}
                            {errorsEditProfile.contactNumber?.type === "pattern" && (<div className="invalid-feedback">Invalid phone number !</div>)}
                            {errorsEditProfile.contactNumber?.type === "maxLength" && (<div className="invalid-feedback">Invalid phone number !</div>)}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <label>Website</label>
                            <input type="text" placeholder="Website" className="form-control" name="website" {...registerEditProfile("website", {
                                required: true,
                            })} />
                            {errorsEditProfile.website?.type === "required" && (<div className="invalid-feedback">Website is required !</div>)}
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label>Address</label>
                            <input type="text" placeholder="Address" className="form-control" name="address" {...registerEditProfile("address", {
                                required: true,
                            })} />
                            {errorsEditProfile.address?.type === "required" && (<div className="invalid-feedback">Address is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>City</label>
                            <input type="text" placeholder="City" className="form-control" name="city" {...registerEditProfile("city", {
                                required: true,
                            })} />
                            {errorsEditProfile.city?.type === "required" && (<div className="invalid-feedback">City is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>State</label>
                            <input type="text" placeholder="State" className="form-control" name="state" {...registerEditProfile("state", {
                                required: true,
                            })} />
                            {errorsEditProfile.state?.type === "required" && (<div className="invalid-feedback">State is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>ZipCode</label>
                            <input type="text" placeholder="ZipCode" className="form-control" name="zipCode" {...registerEditProfile("zipCode", {
                                required: true,
                            })} />
                            {errorsEditProfile.zipCode?.type === "required" && (<div className="invalid-feedback">ZipCode is required !</div>)}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Country</label>
                            <select className='form-control' name='countryID' {...registerEditProfile("countryID", {
                                required: true,
                            })}>
                                {
                                    CountryState.countryResult && CountryState.countryResult.length > 0 ? CountryState.countryResult.map((item, index) => {
                                        return <>
                                            {/* <ReactCountryFlag countryCode="US" svg /> */}
                                            <option key={index} value={item.ID}> {item.CountryName}</option>
                                        </>
                                    }) : <option disabled={true}>Select Country</option>
                                }
                            </select>
                            {errorsEditProfile.countryID?.type === "required" && (<div className="invalid-feedback">Country is required !</div>)}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseEditProfileModal()}>Close</button>
                            <Button title={'Submit'} buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>

            {/* change password success */}
            <Modal
                showModal={ProfileState.changePasswordPortalSuccessModal}
                title="Success"
                onHide={handleSuccessClose}
                onSaveChanges={handleSuccessClose}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{ProfileState.changePasswordPortalMessage}</p>
                </div>

            </Modal>

            {/* change password */}
            <Modal
                showModal={openChangePasswordModel}
                title={'CHANGE PASSWORD'}
                onHide={() => handleCloseChangePasswordModal()}
                width={500}
                showFooter={false}
            >

                {ProfileState.changePasswordPortalError ? <Alert type="alert-danger" text={ProfileState.changePasswordPortalError} closeBtn={alertCancel} /> : ''}

                <form onSubmit={handleSubmit(handlePasswordChange)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3" style={{ position: 'relative' }}>
                            <label>Old Password</label>
                            <input type={oldPasswordShow ? 'text' : 'password'} placeholder="Old Password" className="form-control" name="oldPassword" {...register("oldPassword", {
                                required: true,
                            })} />
                            {
                                oldPasswordShow ? <EyeLineIcon size="1.1em" style={Styles.eyeIcon} onClick={() => setOldPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Styles.eyeIcon} onClick={() => setOldPasswordShow((pre) => !pre)} />
                            }
                            {errors.oldPassword?.type === "required" && (
                                <div className="invalid-feedback">Old password is required !</div>
                            )}
                        </div>

                        <div className="col-md-12 mb-2">
                            <label>New Password</label>
                            <input type={newPasswordShow ? 'text' : 'password'} placeholder="New Password" className="form-control" name="newPassword" {...register("newPassword", {
                                required: true,
                                //  minLength: 4,
                                validate: (value) => {
                                    return PASSWORD_VALIDATE.test(value)
                                }
                            })} />
                            <div style={Styles.iconContainer}>
                                {
                                    newPasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setNewPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setNewPasswordShow((pre) => !pre)} />
                                }
                                
                            </div>

                            {errors.newPassword?.type === "required" && (<div className="invalid-feedback">New password is required !</div>)}
                            {errors.newPassword && errors.newPassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format (Example@123) !</div> : ''}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>Confirm Password</label>
                            <input type={confirmPasswordShow ? 'text' : 'password'} placeholder="Confirm Password" className="form-control" name="confirmPassword" {...register("confirmPassword", {
                                required: true,
                                minLength: 4,
                                validate: (value) => {
                                    return value === password;
                                }
                            })} />
                            <div style={Styles.iconContainer}>
                                {
                                    confirmPasswordShow ? <EyeLineIcon style={{ cursor: 'pointer' }} size="1.1em" onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                                }
                                {/* {
                                    errors.confirmPassword ? <CloseCircleLineIcon size="1.1em" color='#e74c3c' /> : ''
                                }
                                {confirmPassword !== '' && !errors.confirmPassword ? <CheckboxCircleLine size="1.1em" color='#00b894' /> : ''} */}

                            </div>

                            {errors.confirmPassword?.type === "required" && (<div className="invalid-feedback">Confirm password is required !</div>)}
                            {errors.confirmPassword && errors.confirmPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm password should contain at least 4 characters !</div> : ''}
                            {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="invalid-feedback">Password and confirm password not match !</div> : ''}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCloseChangePasswordModal()}>Close</button>
                            <Button title={'Submit'} buttonClass="btn btn-primary" loading={ProfileState.changePasswordPortalLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>


        </>
    )
}

const Styles = {
    eyeIcon: {
        position: 'absolute',
        right: '12px',
        top: '42px',
        cursor: 'pointer'
    },
    iconContainer: {
        position: 'absolute',
        top: '36px',
        right: '12px'
    }
}

export default Profile