import React, { useEffect } from 'react'
import profileAction from './../../redux/profile/actions'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import Style from './MyProfile.module.css'

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const { getProfileDataList } = profileAction;

const MyProfile = () => {
    const dispatch = useDispatch();
    const profileList = useSelector((state) => state.profile.profileListResult);

    useEffect(() => {
        // dispatch(getProfileDataList());
    }, []);

    return (
        <>
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row pb-2 justify-content-center">
                        <div className="col-lg-8 col-xl-7 profile-center">
                            <div className="tab-content tab-content-profile">
                                <div
                                    className="tab-pane fade active show"
                                    id="profile-profile"
                                    role="tabpanel"
                                >
                                    <div className={Style.iq_card} id='profileuper-card '>

                                        <div class='top-headerprofile'>
                                            <p className='para-my-profil ml-2 pt-1'>My Profile</p>
                                        </div>
                                        <div className={Style.iq_card_body}>
                                            <div className="user-detail text-center ">
                                                <div className="user-profile user-profil-my-pro pt-2">
                                                    <div className={`${Style.avatar_div} bg-primary pt-1`} id='user-cirle-my-pro'>
                                                        <p className={Style.avatar_text}>{profileList && profileList.length > 0 ? profileList[0].Username.charAt(0).toUpperCase() : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="profile-detail mt-1">
                                                    <h3 className="d-inline-block text-capitalize">{profileList && profileList.length > 0 ? profileList[0].Username : ''}</h3>
                                                    <br />
                                                    <p className="d-inline-block pl-0 pb-3 para-user-name-1">{profileList && profileList.length > 0 ? profileList[0].Role : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <div className={Style.iq_card} Id='lower-part-profile' >
                                            <div className="d-flex justify-content-center">
                                                <div className='header-part-profile-p'>
                                                    <h4 className="card-title-about">About User</h4>
                                                </div>
                                            </div>
                                            <div className='lowe-part-profile-inner mt-3'>

                                                <div className={Style.iq_card_body}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>

                                                            <h6 className='about-user-th'>Name:</h6>
                                                            <p>{profileList && profileList.length > 0 ? profileList[0].Name : ''}</p>

                                                        </div>

                                                        <div className='col-md-6 text-right'>

                                                            <h6 className='about-user-th'>Email:</h6>
                                                            <p>{profileList && profileList.length > 0 ? profileList[0].ContactEmailID : ''}</p>

                                                        </div>
                                                        <div className='col-md-6'>

                                                            <h6 className='about-user-th'>Contact:</h6>
                                                            <p>{profileList && profileList.length > 0 ? profileList[0].ContactNumber : ''}</p>

                                                        </div>

                                                        <div className='col-md-6 text-right'>

                                                            <h6 className='about-user-th'>Address:</h6>
                                                            <p>{profileList && profileList.length > 0 && profileList[0].Address != '' ? profileList[0].Address : 'Not Available'}</p>

                                                        </div>

                                                        <div className='col-md-6'>

                                                            <h6 className='about-user-th'>City:</h6>
                                                            <p>{profileList && profileList.length > 0 && profileList[0].City != '' ? profileList[0].City : 'Not Available'}</p>

                                                        </div>

                                                        <div className='col-md-6 text-right'>

                                                            <h6 className='about-user-th'>ZipCode:</h6>
                                                            <p>{profileList && profileList.length > 0 && profileList[0].ZipCode != '' ? profileList[0].ZipCode : 'Not Available'}</p>

                                                        </div>

                                                        <div className='col-md-6'>

                                                            <h6 className='about-user-th'>State:</h6>
                                                            <p>{profileList && profileList.length > 0 && profileList[0].State != '' ? profileList[0].State : 'Not Available'}</p>

                                                        </div>

                                                        {/* <div className='col-md-6 mb-3'>

                                                            <h6 className='about-user-th'>TariffGroup:</h6>
                                                            <p>{userDetails && userDetails.TariffGroup != '' ? userDetails.TariffGroup : 'Not Available'}</p>

                                                        </div> */}

                                                        {/* <div className='col-md-6 mb-3'>

                                                            <h6 className='about-user-th'>Allow Seller Creation:</h6>
                                                            <p>{profileList && profileList[0].AllowSellerCreation ? 'Yes' : 'No'}</p>

                                                        </div> */}
                                                        {/* <div className='col-md-6 mb-3'>

                                                            <h6 className='about-user-th'>Allow Tariff Creation:</h6>
                                                            <p>{userDetails && userDetails.AllowTariffCreation ? 'Yes' : 'No'}</p>

                                                        </div> */}
                                                        <div className='col-md-6 text-right'>

                                                            <h6 className='about-user-th'>Joined:</h6>
                                                            <p>{profileList && profileList.length > 0 ? moment(profileList[0].CreatedDtTm).format(dateFormat) : ''}</p>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default MyProfile