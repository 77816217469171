import { all } from 'redux-saga/effects';
// import userSagas from './user/saga'
import authSagas from './auth/saga';
import countrySagas from './country/saga';
import profileSagas from './profile/saga';
import activitySagas from './activity/saga';
import dashboardSagas from './dashboard/saga';
import activationRequestSagas from './activationRequest/saga'

export default function* rootSaga() {
        yield all([
                // countrySagas(),
                authSagas(),
                profileSagas(),
                activitySagas(),
                dashboardSagas(),
                activationRequestSagas(),
        ])
}