import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, } from '../../helpers/fackBackend_Helper';

function* getProfileListDataResponse() {

    try {
        const response = yield call(getList, '/getProfile');
        if (response.response.Status) {
            yield put(
                actions.getProfileDataListSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getProfileDataListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getProfileDataListFailed(error));
    }
}

function* editProfileListDataResponse({ payload: { Id, data } }) {
    
    try {
        const response = yield call(addData, '/updateProfile/' + Id, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getProfile');
            if (responseList.response.Status) {
                yield put(
                    actions.editProfileListSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.editProfileListFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.editProfileListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.editProfileListFailed(error));
    }
}


function* changePasswordPortalResponse({ payload: { data } }) {
    try {
      const response = yield call(addData, '/aggregatorchangePassword', data);
      if (response.response.Status) {
        yield put(actions.changePasswordPortalSuccess(response.response.Message));
      } else {
        yield put(actions.changePasswordPortalFailed(response.response.Message));
      }
    } catch (error) {
      yield put(actions.changePasswordPortalFailed(error));
    }
  }
//   export function* watchChangePasswordResponse() {
//     yield takeEvery(actions.CHANGE_PASSWORD, changePasswordResponse)
//   }

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_POFILE_DATA_LIST, getProfileListDataResponse)]);
    yield all([takeEvery(actions.EDIT_PROFILE_LIST, editProfileListDataResponse)]);
    yield all([takeEvery(actions.CHANGE_PASSWORD_PORTAL, changePasswordPortalResponse)]);

}