import React from 'react';

function CardLayout({ title, children, cardClass = '', style }) {
    return (
        <div id="content-page" style={style} class={`content-page ${cardClass}`}>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="iq-card card-style-layout mb-2">
                            <div class="iq-card-header d-flex justify-content-between ">
                                <div class="iq-header-title">
                                    <h4 class="card-title">{title}</h4>
                                </div>
                            </div>
                            <div class="iq-card-body pt-2">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default CardLayout;
