import LoaderImage from '../../assets/images/mvno-loader.gif'

const Loader = () => {
    return (
        <div id="loading">
            <div id="loading-center">
                <img src={LoaderImage} height={100} />
            </div>
        </div>
    )
}

export default Loader