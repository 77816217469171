const actions = {
  GET_DASHBOARD_COUNT: "GET_DASHBOARD_COUNT",
  GET_DASHBOARD_COUNT_SUCCESS: "GET_DASHBOARD_COUNT_SUCCESS",
  GET_DASHBOARD_COUNT_FAILED: "GET_DASHBOARD_COUNT_FAILED",
  GET_MONTHLY_ACTIVATION_COUNT: "GET_MONTHLY_ACTIVATION_COUNT",
  GET_MONTHLY_ACTIVATION_COUNT_SUCCESS: "GET_MONTHLY_ACTIVATION_COUNT_SUCCESS",
  GET_MONTHLY_ACTIVATION_COUNT_FAILED: "GET_MONTHLY_ACTIVATION_COUNT_FAILED",
  GET_YEARLY_ACTIVATION_COUNT: "GET_YEARLY_ACTIVATION_COUNT",
  GET_YEARLY_ACTIVATION_COUNT_SUCCESS: "GET_YEARLY_ACTIVATION_COUNT_SUCCESS",
  GET_YEARLY_ACTIVATION_COUNT_FAILED: "GET_YEARLY_ACTIVATION_COUNT_FAILED",
  GET_SALES_AND_DEALER_COUNT: "GET_SALES_AND_DEALER_COUNT",
  GET_SALES_AND_DEALER_COUNT_SUCCESS: "GET_SALES_AND_DEALER_COUNT_SUCCESS",
  GET_SALES_AND_DEALER_COUNT_FAILED: "GET_SALES_AND_DEALER_COUNT_FAILED",
  UPDATE_JAR_STATUS: "UPDATE_JAR_STATUS",
  UPDATE_JAR_STATUS_SUCCESS: "UPDATE_JAR_STATUS_SUCCESS",
  UPDATE_JAR_STATUS_FAILED: "UPDATE_JAR_STATUS_FAILED",
  OPEN_JAR_STATUS_MODEL: "OPEN_JAR_STATUS_MODEL",
  CLOSE_JAR_STATUS_MODEL: "CLOSE_JAR_STATUS_MODEL",
  GET_ACTIVATION_COUNT: "GET_ACTIVATION_COUNT",
  GET_ACTIVATION_COUNT_SUCCESS: "GET_ACTIVATION_COUNT_SUCCESS",
  GET_ACTIVATION_COUNT_FAILED: "GET_ACTIVATION_COUNT_FAILED",
  GET_BOOM_DATA: "GET_BOOM_DATA",
  GET_BOOM_DATA_SUCCESS: "GET_BOOM_DATA_SUCCESS",
  GET_BOOM_DATA_FAILED: "GET_BOOM_DATA_FAILED",

  getBoomData: () => ({
    type: actions.GET_BOOM_DATA,
  }),
  getBoomDataSuccess: (data) => ({
    type: actions.GET_BOOM_DATA_SUCCESS,
    payload: { data },
  }),
  getBoomDataFailed: (error) => ({
    type: actions.GET_BOOM_DATA_FAILED,
    payload: { error },
  }),

  openJarStatusModel: () => ({
    type: actions.OPEN_JAR_STATUS_MODEL,
  }),
  closeJarStatusModel: () => ({
    type: actions.CLOSE_JAR_STATUS_MODEL,
  }),

  jarRunningStatus: (data) => ({
    type: actions.UPDATE_JAR_STATUS,
    payload: { data },
  }),
  jarRunningStatusSuccess: (message) => ({
    type: actions.UPDATE_JAR_STATUS_SUCCESS,
    payload: { message },
  }),
  jarRunningStatusFailed: (error) => ({
    type: actions.UPDATE_JAR_STATUS_FAILED,
    payload: { error },
  }),

  getDashboardCount: () => ({
    type: actions.GET_DASHBOARD_COUNT,
  }),
  getDashboardCountSuccess: (data) => ({
    type: actions.GET_DASHBOARD_COUNT_SUCCESS,
    payload: { data },
  }),
  getDashboardCountFailed: (error) => ({
    type: actions.GET_DASHBOARD_COUNT_FAILED,
    payload: { error },
  }),
  getMonthlyActivationCount: () => ({
    type: actions.GET_MONTHLY_ACTIVATION_COUNT,
  }),
  getMonthlyActivationCountSuccess: (data) => ({
    type: actions.GET_MONTHLY_ACTIVATION_COUNT_SUCCESS,
    payload: { data },
  }),
  getMonthlyActivationCountFailed: (error) => ({
    type: actions.GET_MONTHLY_ACTIVATION_COUNT_FAILED,
    payload: { error },
  }),
  getYearlyActivationCount: () => ({
    type: actions.GET_YEARLY_ACTIVATION_COUNT,
  }),
  getYearlyActivationCountSuccess: (data) => ({
    type: actions.GET_YEARLY_ACTIVATION_COUNT_SUCCESS,
    payload: { data },
  }),
  getYearlyActivationCountFailed: (error) => ({
    type: actions.GET_YEARLY_ACTIVATION_COUNT_FAILED,
    payload: { error },
  }),
  getSalesAndDealerCount: () => ({
    type: actions.GET_SALES_AND_DEALER_COUNT,
  }),
  getSalesAndDealerCountSuccess: (data) => ({
    type: actions.GET_SALES_AND_DEALER_COUNT_SUCCESS,
    payload: { data },
  }),
  getSalesAndDealerCountFailed: (error) => ({
    type: actions.GET_SALES_AND_DEALER_COUNT_FAILED,
    payload: { error },
  }),

  getActivationCount: () => ({
    type: actions.GET_ACTIVATION_COUNT,
  }),
  getActivationCountSuccess: (data) => ({
    type: actions.GET_ACTIVATION_COUNT_SUCCESS,
    payload: { data },
  }),
  getActivationCountFailed: (error) => ({
    type: actions.GET_ACTIVATION_COUNT_FAILED,
    payload: { error },
  }),
};
export default actions;