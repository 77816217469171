import { combineReducers } from "redux";
import user from './user/reducer'
import auth from './auth/reducer';
import profile from './profile/reducer'
import activity from './activity/reducer'
import dashboard from './dashboard/reducer'
import country from './country/reducer';
import activationRequest from './activationRequest/reducer'
const rootReducer = combineReducers({
  user,
  auth,
  profile,
  activity,
  dashboard,
  country,
  activationRequest,
});
export default rootReducer;