import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import OwlCarousel from 'react-owl-carousel';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import { useDispatch, useSelector } from 'react-redux';
// import sideImage from '../assets/images/slide-1.png';
// import sideImagetwo from '../assets/images/slide-2.png';
// import sideImagethree from '../assets/images/slide-3.png';
import Button from "../components/utils/Button";
import AuthActions from '../redux/auth/actions';
import Alert from '../components/utils/Alert';
// import logo from '../assets/images/logo-white.png'
import Long from '../../src/assets/images/Long.png'

const { login, closeAlert } = AuthActions;

function Signin() {

    const dispatch = useDispatch();
    const Auth = useSelector((state) => state.auth);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [passwordShow, setPasswordShow] = useState(false);

    const handleLogin = (data) => {
        dispatch(login(data));
        localStorage.setItem('user', 'Address Fill');
    }

    if (Auth.loginMessage) {
        setTimeout(() => {
            localStorage.setItem("mvno_admin_token", Auth.loginToken);
            window.location.href = '/';

        }, 500);
    }

    const alertCancel = () => {
        dispatch(closeAlert());
    }

    return (
        <section class="sign-in-page">


            <div class="container mt-lg-2 mt-xl-5 pt-5">
                <div class="row no-gutters justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12 bg-white px-4 pb-3 rounded border align-self-center" >
                        <div className='d-flex align-item-center justify-content-center'>
                            <a className='text-center ' href="/">
                                <img src={Long} alt="Logo" style={{ height: "85px" }} />
                            </a>
                        </div>
                        <div>
                            <h2 class="mb-0 mt-4 font-weight-bold text-center" style={{ fontSize: "22px" }}>Sign in</h2>
                            <p className='text-dark text-center' style={{ fontSize: "13px", borderBottom: "2px solid #050405" }}>Enter your username and password to access admin panel.</p>
                        </div>

                        {Auth.loginMessage ? <Alert type="alert-success" text={Auth.loginMessage} closeBtn={alertCancel} /> : ''}
                        {Auth.loginError ? <Alert type="alert-danger" text={Auth.loginError} closeBtn={alertCancel} /> : ''}

                        <form className="mt-4" onSubmit={handleSubmit(handleLogin)}>

                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Username</label>
                                <input type="text" className="form-control" name='username' {...register('username', {
                                    required: true
                                })} placeholder="Enter username" />
                                {errors.username && errors.username.type === "required" != '' ? <div className="invalid-feedback">Username is required</div> : ''}
                            </div>

                            <div className="form-group" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <input type={passwordShow ? 'text' : 'password'} className="form-control" name='password' {...register('password', {
                                    required: true
                                })} placeholder="Enter password" />
                                {
                                    passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                                }
                                {errors.password && errors.password.type === "required" != '' ? <div className="invalid-feedback">Password is required</div> : ''}
                            </div>
                            <div className="d-inline-block w-100 mt-1">
                                <Button title='Sign in' buttonClass="btn btn-primary float-right w-25" loading={Auth.loginLoading} buttonType='submit' />
                            </div>
                            {/* <div className="sign-info">
                                <Link to="/forget-password" className="float-right"><u>Forgot password</u></Link>
                            </div> */}
                        </form>


                    </div>
                </div>

            </div>
        </section>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '15px',
        top: '40px',
        cursor: 'pointer'
    },
}


export default Signin