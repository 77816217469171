import actions from "./actions";

const initState = {
    userResult: [],
    loading: false,
    userError: null,
    userMessage: null,
    buttonLoading: false,
    userFormModal: false,
    userPasswordModal: false,
    roleResult:[],
   
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_USER:
            return {
                ...state,
                loading: true,
                userMessage: null,
                userError: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false
            };
        case actions.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userResult: action.userResult,
                userMessage: null,
                userError: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false
            };
        case actions.GET_USER_FAILED:
            return {
                ...state,
                loading: false,
                userError: action.userError,
                userMessage: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false
            };
        case actions.ADD_USER:
            return {
                ...state,
                loading: false,
                userMessage: null,
                userError: null,
                buttonLoading: true,
                userFormModal: true,
                userPasswordModal: false,
               
            };
        case actions.ADD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: action.userMessage,
                userResult: action.userResult,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false,
               
            };
        case actions.ADD_USER_FAILED:
            return {
                ...state,
                loading: false,
                userError: action.userError,
                userMessage: null,
                buttonLoading: false,
                userFormModal: true,
                userPasswordModal: false
            };
        case actions.EDIT_USER:
            return {
                ...state,
                loading: false,
                userMessage: null,
                userError: null,
                buttonLoading: true,
                userFormModal: true,
                userPasswordModal: false,
                
            };
        case actions.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: action.userMessage,
                userResult: action.userResult,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false,
               
            };
        case actions.DELETE_USER:
            return {
                ...state,
                loading: false,
                userMessage: null,
                userError: null,
                buttonLoading: true,
                userFormModal: false,
                userPasswordModal: false,
               
            };
        case actions.DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: action.userMessage,
                userResult: action.userResult,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false,
               
            };

        case actions.USER_PASSWORD_RESET:
            return {
                ...state,
                loading: true,
                userMessage: null,
                userError: null,
                buttonLoading: true,
                userFormModal: false,
                userPasswordModal: true,
                
            };
        case actions.USER_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: action.userMessage,
                userResult: action.userResult,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false,
                
            };
            case actions.USER_PASSWORD_RESET_FAILED:
            return {
                ...state,
                loading: false,
                userError: action.userError,
                userMessage: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: true,
               
            };
        case actions.PREPARE_USER_FORM:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                buttonLoading: false,
                userFormModal: true,
                userPasswordModal: false
            };
        case actions.PREPARE_PASSWORD_FORM:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: true
            };
        case actions.RESET_USER:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                buttonLoading: false,
                userFormModal: false,
                userPasswordModal: false
            };
        case actions.GET_USER_ROLE:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                buttonLoading: false,
                userFormModal: true,
                userPasswordModal: false
            };
        case actions.GET_USER_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                roleResult:action.roleResult,
                buttonLoading: false,
                userFormModal: true,
                userPasswordModal: false
            };
            case actions.RESET_USER_MESSAGE:
            return {
                ...state,
                loading: false,
                userError: null,
                userMessage: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}
