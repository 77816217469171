import React from 'react';

const Button = ({ title, buttonClass, onClick, loading, buttonType, style, icon = false, disable }) => {
  return (
    <button type={buttonType} onClick={onClick} style={{ style }} disabled={loading || disable} className={`${buttonClass}`}>
      {icon && !loading ? icon : ''}
      &nbsp;
      {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : title}
    </button>
  )

}

Button.defaultProps = {
  title: 'Button',
  buttonType: 'button',
  loading: false,
  disable: false,
  buttonClass: 'btn',
}

export default Button;

