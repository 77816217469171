const actions = {
    GET_POFILE_DATA_LIST: "GET_POFILE_DATA_LIST",
    GET_POFILE_DATA_LIST_SUCCESS: "GET_POFILE_DATA_LIST_SUCCESS",
    GET_POFILE_DATA_LIST_FAILED: "GET_POFILE_DATA_LIST_FAILED",
    EDIT_PROFILE_LIST: "EDIT_PROFILE_LIST",
    EDIT_PROFILE_LIST_SUCCESS: "EDIT_PROFILE_LIST_SUCCESS",
    EDIT_PROFILE_LIST_FAILED: "EDIT_PROFILE_LIST_FAILED",
    RESET_PROFILE: "RESET_PROFILE",
    PREPARE_EDIT_FORM_MODEL:"PREPARE_EDIT_FORM_MODEL",
    RESET_PROFILE_MESSAGE:"RESET_PROFILE_MESSAGE",
    OPEN_EDIT_PROFILE_MODEL:"OPEN_EDIT_PROFILE_MODEL",
    CLOSE_EDIT_PROFILE_MODEL:"CLOSE_EDIT_PROFILE_MODEL",
    OPEN_CHANGE_PASSWORD_MODEL:"OPEN_CHANGE_PASSWORD_MODEL",
    CLOSE_CHANGE_PASSWORD_MODEL:"CLOSE_CHANGE_PASSWORD_MODEL",
    CHANGE_PASSWORD_PORTAL: 'CHANGE_PASSWORD_PORTAL',
    CHANGE_PASSWORD_PORTAL_SUCCESS: 'CHANGE_PASSWORD_PORTAL_SUCCESS',
    CHANGE_PASSWORD_PORTAL_FAILED: 'CHANGE_PASSWORD_PORTAL_FAILED',
    CHANGE_PASSWORD_PORTAL_SUCCESS_MODAL_CLOSE: 'CHANGE_PASSWORD_PORTAL_SUCCESS_MODAL_CLOSE',
    CLOSE_ALERT:'CLOSE_ALERT',

    changePasswordPortalSuccessModalClose: () => ({
      type: actions.CHANGE_PASSWORD_PORTAL_SUCCESS_MODAL_CLOSE,
    }),  
    closeAlert: () => ({
        type: actions.CLOSE_ALERT,
      }),


    getProfileDataList: () => ({
        type: actions.GET_POFILE_DATA_LIST,
    }),
    getProfileDataListSuccess: (profileListResult) => ({
        type: actions.GET_POFILE_DATA_LIST_SUCCESS,
        profileListResult
    }),
    getProfileDataListFailed: (profileError) => ({
        type: actions.GET_POFILE_DATA_LIST_FAILED,
        profileError
    }),
    editProfileList: (Id, data) => ({
        type: actions.EDIT_PROFILE_LIST,
        payload: { Id, data }
    }),
    editProfileListSuccess: (profileMessage, profileListResult) => ({
        type: actions.EDIT_PROFILE_LIST_SUCCESS,
        profileMessage,
        profileListResult

    }),
    editProfileListFailed: (profileError) => ({
        type: actions.EDIT_PROFILE_LIST_FAILED,
        profileError,
    }),
    resetProfile: () => ({
        type: actions.RESET_PROFILE,
    }),
    prepareEditFormModel: () => ({
        type: actions.PREPARE_EDIT_FORM_MODEL,
    }),
    resetProfileMessage: () => ({
        type: actions.RESET_PROFILE_MESSAGE,
    }),

    openEditProfileModel:()=>({
        type:actions.OPEN_EDIT_PROFILE_MODEL,
    }),
    closeEditProfileModel:()=>({
        type:actions.CLOSE_EDIT_PROFILE_MODEL,
    }),
    openChangePasswordModel:()=>({
        type:actions.OPEN_CHANGE_PASSWORD_MODEL,
    }),
    closeChangePasswordModel:()=>({
        type:actions.CLOSE_CHANGE_PASSWORD_MODEL,
    }),
    changePasswordPortal: (data) => ({
        type: actions.CHANGE_PASSWORD_PORTAL,
        payload: { data },
      }),
      changePasswordPortalSuccess: (message) => ({
        type: actions.CHANGE_PASSWORD_PORTAL_SUCCESS,
        payload: { message },
      }),
      changePasswordPortalFailed: (error) => ({
        type: actions.CHANGE_PASSWORD_PORTAL_FAILED,
        payload: { error },
      }),
}
export default actions;