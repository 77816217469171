import actions from "./actions";

const initState = {
  dashboardError: null,
  dashboardMessage: null,
  dashboardLoading: false,
  dashboardMessage: null,
  dashboardCountData: [],
  dashboardActivityLoading: false,
  dashboardActivityMessage: null,
  dashboardActivityError: null,
  monthlyActivationCountData: [],
  yearlyActivationCountData: [],

  showJarStatusModal: false,
  switchOnOff: false,
  jarStatusMessage: null,
  jarStatusError: null,

  activationCountData: [],
  activationCountLoading: false,
  activationCountError: null,

  getBoomDataLoading: false,
  getBoomDataError: null,
  getBoomDataMessage: null,
  getBoomDataList: new Object(),
};



export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_BOOM_DATA:
      return {
        ...state,
        getBoomDataLoading: true,
        getBoomDataError: null,
        getBoomDataMessage: null,
      };
    case actions.GET_BOOM_DATA_SUCCESS:
      return {
        ...state,
        getBoomDataLoading: false,
        getBoomDataError: null,
        getBoomDataMessage: null,
        getBoomDataList: action.payload.data,
      };
    case actions.GET_BOOM_DATA_FAILED:
      return {
        ...state,
        getBoomDataLoading: false,
        getBoomDataError: action.payload.error,
        getBoomDataMessage: null,
        getBoomDataList: new Object(),
      };



    case actions.GET_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardLoading: true,
        dashboardMessage: null,
        dashboardError: null,
      };
    case actions.GET_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: null,
        dashboardCountData: action.payload.data,
      };
    case actions.GET_DASHBOARD_COUNT_FAILED:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: action.payload.error,
      };
    case actions.GET_MONTHLY_ACTIVATION_COUNT:
      return {
        ...state,
        dashboardLoading: true,
        dashboardMessage: null,
        dashboardError: null,
        dashboardActivityLoading: true,
      };
    case actions.GET_MONTHLY_ACTIVATION_COUNT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: null,
        monthlyActivationCountData: action.payload.data,
        dashboardActivityLoading: false,
      };
    case actions.GET_MONTHLY_ACTIVATION_COUNT_FAILED:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: action.payload.error,
        dashboardActivityLoading: false,

      };
    case actions.GET_YEARLY_ACTIVATION_COUNT:
      return {
        ...state,
        dashboardLoading: true,
        dashboardMessage: null,
        dashboardError: null,
      };
    case actions.GET_YEARLY_ACTIVATION_COUNT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: null,
        yearlyActivationCountData: action.payload.data,
      };
    case actions.GET_YEARLY_ACTIVATION_COUNT_FAILED:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: action.payload.error,
      };

    case actions.GET_SALES_AND_DEALER_COUNT:
      return {
        ...state,
        dashboardLoading: true,
        dashboardMessage: null,
        dashboardError: null,
      };
    case actions.GET_SALES_AND_DEALER_COUNT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: null,
        SalesAndDealerCountData: action.payload.data,
      };
    case actions.GET_SALES_AND_DEALER_COUNT_FAILED:
      return {
        ...state,
        dashboardLoading: false,
        dashboardMessage: null,
        dashboardError: action.payload.error,
      };



    case actions.OPEN_JAR_STATUS_MODEL:
      return {
        ...state,
        showJarStatusModal: true,
      };
    case actions.CLOSE_JAR_STATUS_MODEL:

      return {
        ...state,
        showJarStatusModal: false,
      };

    case actions.UPDATE_JAR_STATUS:
      return {
        ...state,
        showJarStatusModal: true,
        jarStatusMessage: null,
        jarStatusError: null,
      };
    case actions.UPDATE_JAR_STATUS_SUCCESS:
      return {
        ...state,
        showJarStatusModal: false,
        jarStatusMessage: action.payload.message,
        jarStatusError: null,
      };
    case actions.UPDATE_JAR_STATUS_FAILED:
      return {
        ...state,
        showJarStatusModal: false,
        jarStatusMessage: null,
        jarStatusError: action.payload.error,
      };


    case actions.GET_ACTIVATION_COUNT:
      return {
        ...state,
        activationCountData: [],
        activationCountLoading: true,
        activationCountError: null,
      };
    case actions.GET_ACTIVATION_COUNT_SUCCESS:
      return {
        ...state,
        activationCountData: action.payload.data,
        activationCountLoading: false,
        activationCountError: null,
      };
    case actions.GET_ACTIVATION_COUNT_FAILED:
      return {
        ...state,
        activationCountLoading: false,
        activationCountError: action.payload.error,

      };


    default:
      return state;
  }
}
