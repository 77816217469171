import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';

function* updateJarRunningStatus({ payload: { data } }) {
    try {
        const response = yield call(addData, '/jarRunningStatus', data);
        if (response.Status) {
            yield put(actions.jarRunningStatusSuccess(response.Message));
        }
    } catch (error) {
        yield put(actions.jarRunningStatusFailed(error));
    }
}

function* getDashboardCountResponse() {
    try {
        const response = yield call(getList, '/getDashboardCount');
        if (response.Status) {
            yield put(actions.getDashboardCountSuccess(response));
        } else {
            yield put(actions.getDashboardCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getDashboardCountFailed(error));
    }
}

function* getMonthlyActivationCountDataResponse() {
    try {
        const response = yield call(getList, '/getMonthlyActivationCount');
        if (response.Status) {
            yield put(actions.getMonthlyActivationCountSuccess(response));
        } else {
            yield put(actions.getMonthlyActivationCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getMonthlyActivationCountFailed(error));
    }
}

function* getYearlyActivationCountDataResponse() {
    try {
        const response = yield call(getList, '/getYearlyActivationCount');
        if (response.Status) {
            yield put(actions.getYearlyActivationCountSuccess(response));
        } else {
            yield put(actions.getYearlyActivationCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getYearlyActivationCountFailed(error));
    }
}

function* getSalesAndDelaerCountResponse() {
    try {
        const response = yield call(getList, '/salesAndDealerReport');
        if (response.Status) {
            yield put(actions.getSalesAndDealerCountSuccess(response.Data));
        } else {
            yield put(actions.getSalesAndDealerCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getSalesAndDealerCountFailed(error));
    }
}

function* getActivationCountDataResponse() {
    try {
        const response = yield call(getList, '/activationCount');
        if (response.Status) {
            yield put(actions.getActivationCountSuccess(response.Data));
        } else {
            yield put(actions.getActivationCountFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getActivationCountFailed(error));
    }
}

function* getBoomDataResponse() {
    try {
        const response = yield call(getList, '/getBoomDetails');
        if (response.Status) {
            yield put(actions.getBoomDataSuccess(response.Data));
        } else {
            yield put(actions.getBoomDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getBoomDataFailed(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_DASHBOARD_COUNT, getDashboardCountResponse),
        takeEvery(actions.GET_MONTHLY_ACTIVATION_COUNT, getMonthlyActivationCountDataResponse),
        takeEvery(actions.GET_YEARLY_ACTIVATION_COUNT, getYearlyActivationCountDataResponse),
        takeEvery(actions.GET_SALES_AND_DEALER_COUNT, getSalesAndDelaerCountResponse),
        takeEvery(actions.UPDATE_JAR_STATUS, updateJarRunningStatus),
        takeEvery(actions.GET_ACTIVATION_COUNT, getActivationCountDataResponse),
        takeEvery(actions.GET_BOOM_DATA, getBoomDataResponse),
    ]);
}
