import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import Button from '../../components/utils/Button';
import { set, useForm, Controller, useFieldArray } from "react-hook-form";
import moment from "moment";
import CardLayout from '../../components/utils/CardLayout';
import SearchBar from '../../components/utils/SearchBar';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../../components/utils/Table';
import authActions from '../../redux/auth/actions';
import { isDealer, isAdmin, isAdminAgent, validateIMEI, removeSameElementFromArray } from '../../helpers/commonFunction';
import { convertDateTimeToRequiredFormatTZ } from '../../helpers/commonFunction';
import activationRequestActions from '../../redux/activationRequest/actions';
import Tag from '../../components/utils/Tag';
import { Link } from 'react-router-dom';
import Modal from '../../components/utils/Model';
import Styles from './ActivationRequest.module.css';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import Alert from '../../components/utils/Alert';





const TIMEZONE = process.env.REACT_APP_TIMEZONE;
const LONG_TIME_FORMAT = process.env.REACT_APP_LONG_TIME_FORMAT;

const { getNetworkListByAggrID, getDealer, getShortCode, getActivationRequestList, accessTransactionModal, transactionIdModal, closeTransactionIdModal,
    prepareCompleteModal, closeCompleteModal, compeleteActivation, lockActivation, unLockActivation, resetActivation, prepareCancelModal, closeCancelModal, getErrorCode, cancelActivation } = activationRequestActions
function ActivationRequest() {
    const dispatch = useDispatch();
    const { getUserDataByToken } = authActions
    const { tokenData } = useSelector((state) => state.auth);
    const { networkResult, dealerResult, shortCodeResult, activationRequestResult, activationListLoading, requestData, transectionIdModal, modalViewLoader, LockedFailedmessage, lockLoading, unLockMessage,
        completeModal, cancelModal, errorCodeResult, buttonLoading, apiErrorModal, messageModal } = useSelector((state) => state.activationRequest);
    const [filterText, setFilterText] = useState("");
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [editId, seteditId] = useState(null);
    const [formButton, setFormButton] = useState('');
    const [formTitle, setFormTitle] = useState('');
    const [lockID, setlockID] = useState('');
    const [disableEID, setDisableEID] = useState(false);
    const [mobileDisable, setmobileDisable] = useState(false);
    const [aggregatorID, setAggregatorID] = useState('');
    const [simTypeID, setSimTypeID] = useState('');
    const [searchData, setSearchData] = useState({
        statusID: '10',
        serialNumber: '',
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        dealerID: "",
        networkID: ""
    });
    const [statusData, setstatusData] = useState({
        tableName: "activationrequest",
        fieldName: "RequestStatus"
    });
    const [remarkContent, setRemarkContent] = useState({
        remark: '',
        errorCode: '',
    });
    const [infoData, setinfoData] = useState({
        eId: '',
        MSISDN: '',
        orderNumber: '',
        accountNumber: '',
        transactionId: '',
    });

    const { register: registerFilter, handleSubmit: handleSubmit, formState: { errors: filterError }, watch, reset } = useForm({ defaultValues: searchData });
    const { register: registerComplete, handleSubmit: handleCompleteActivation, formState: { errors: completeError }, watch: watchComplete, reset: resetComplete, setValue } = useForm({ defaultValues: infoData });
    const { register: registerCancel, handleSubmit: handleCancelActivation, formState: { errors: cancelError }, watch: watchCancel, reset: resetCancel, } = useForm({ defaultValues: remarkContent });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const aggregatorId = tokenData && tokenData.length > 0 && tokenData[0].ID

    useEffect(() => {
        dispatch(getDealer())
        dispatch(getShortCode(statusData));
        dispatch(getUserDataByToken())
    }, [])

    useEffect(() => {
        dispatch(getNetworkListByAggrID(aggregatorId))
    }, [tokenData])

    useEffect(() => {
        dispatch(getActivationRequestList(searchData, aggregatorId));
    }, [aggregatorId])

    useEffect(() => {
        if (LockedFailedmessage !== null && LockedFailedmessage !== undefined) {
            displayAlert(LockedFailedmessage, 'alert-danger');
        }
        if (apiErrorModal !== null && apiErrorModal !== undefined) {
            displayAlert(apiErrorModal, 'alert-danger');
        }
        if (messageModal !== null && messageModal !== undefined) {
            displayAlert(messageModal, 'alert-success');
            resetCancel()
            resetComplete()
        }

    }, [LockedFailedmessage, apiErrorModal, messageModal]);

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const onSubmit = (data) => {
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        dispatch(getActivationRequestList(newData, aggregatorId));
    }

    let columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ACTION', key: 'action' },
        { displayName: 'STATUS', key: 'masterStatus' },
        { displayName: 'TRANSACTION ID', key: 'transactionId' },
        { displayName: 'IMEI', key: 'imei' },
        { displayName: 'SERIAL/EID', key: 'serialNumber' },
        { displayName: 'DEALER', key: 'dealer' },
        { displayName: 'DATE', key: 'requestedDtTm' },
        { displayName: 'NETWORK', key: 'NetworkName' },
        { displayName: 'AGGREGATOR', key: 'AggregatorName' },
        { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
        { displayName: 'TYPE', key: 'requestType' },
        { displayName: 'SIM', key: 'simType' },
        { displayName: 'REMARK', key: 'remark' },
    ];
    const filteredItems = activationRequestResult && activationRequestResult.length > 0 && activationRequestResult.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
    const data = useMemo(() => {
        if (filteredItems && filteredItems.length) {
            return filteredItems.map((activation, key) => {
                return {
                    key: key + 1,
                    action: <>{
                        
                        activation.RequestStatusID == 10 ? (
                            activation.lockFlag === 0 ?
                                <span>
                                    {(activation.lockFlag === 0) && (activation.otherLockFlag === 0) ?
                                        <span className='d-flex'>
                                            {
                                                lockLoading && lockID == activation.ID ? <div className="spinner-border text-secondary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> :
                                                    <div className="icon iq-action-icon-box rounded-circle bg-info" onClick={() => handleLockActivation(activation.ID)}>
                                                        <i className="ri-lock-line" ></i>
                                                    </div>
                                            }
                                        </span> :
                                        <>
                                            <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleLockActivation(activation.ID)}>
                                                <i className="ri-forbid-line"></i>
                                            </div>
                                        </>

                                    }
                                </span>
                                :
                                <div className='d-flex'>
                                    <div className="icon iq-action-icon-box rounded-circle bg-success" onClick={() => handleCompleteModalOpen(activation.ID,activation)}> <i className="ri-check-line"></i></div>
                                    {
                                        activation.cancelModalLoading ? <div className="spinner-border text-secondary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : <div className="icon iq-action-icon-box rounded-circle bg-danger" onClick={() => handleRemarkModalOpen(activation.ID)}> <i className="ri-close-fill"></i></div>
                                    }
                                    {
                                        activation.releaseLoading ? <div className="spinner-border text-secondary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> :
                                            <div className="icon iq-action-icon-box rounded-circle bg-secondary" onClick={() => handleUnLockActivation(activation.ID)} style={{ 'cursor': 'pointer' }}> <i className="ri-lock-unlock-line"></i></div>
                                    }
                                </div>
                        ) : ''

                    }
                    </>,
                    serialNumber: activation.SerialNumber,
                    NetworkName: activation.NetworkName,
                    AggregatorName: activation.AggregatorID,
                    transactionId: <Link to="#" style={{ color: 'blue' }} onClick={() => handleTransactionIdModel(activation)}>
                        <span data-toggle="tooltip" data-placement="top" title="View">
                            {activation.TransactionID}
                        </span>
                    </Link>,
                    imei: activation.IMEI,
                    dealer: activation.DealerName,
                    mobileNumber: activation.MSISDN,
                    requestedDtTm: activation.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(activation.RequestedDtTm) : '',
                    requestType: activation.RequestType,
                    simType: activation.SimType,
                    masterStatus: <Tag color={`${(activation.RequestStatusID === 13) ? 'badge-danger' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22) || activation.RequestStatusID === 27) ? 'badge-warning' : (activation.RequestStatusID === 12) ? 'badge-success' : (activation.RequestStatusID === 32) ? 'badge-info' : 'badge-secondary'}`} title={`${(activation.RequestStatusID === 13) ? 'Cancelled' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22)) ? 'Processing' : (activation.RequestStatusID === 12) ? 'Processed' : activation.RequestStatusID === 27 ? 'Assigned to Care' : (activation.RequestStatusID === 32) ? 'Picked' : 'Pending'}`}> </Tag>,
                    requestStatus: (<span className={`${(activation.RequestStatusID === 13 || activation.RequestStatusID === 24 || activation.RequestStatusID === 32) ? 'text-danger' : (activation.RequestStatusID === 11 || (activation.RequestStatusID >= 18 && activation.RequestStatusID <= 22) || activation.RequestStatusID === 27) ? 'text-warning' : (activation.RequestStatusID === 12) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{activation.RequestStatus}</strong></span>),
                    remark: activation.Remark,
                }
            });

        } else {
            return [];
        }
    }, [filteredItems]);


    const handleLockActivation = (activationID) => {
        const lockActivationData = {
            activationId: activationID,
        }
        setlockID(activationID)
        searchData.fromDate = moment(searchData.fromDate).format('YYYY-MM-DD');
        searchData.toDate = moment(searchData.toDate).format('YYYY-MM-DD');
        dispatch(lockActivation(lockActivationData, aggregatorId, searchData));
    }

    const handleCompleteModalOpen = (id,activation) => {
        setSimTypeID(activation.SimTypeID)
        dispatch(prepareCompleteModal())
        seteditId(id);
        setFormButton('Submit')
        setFormTitle('Complete Activation')
    }
    const handleCancelComplete = () => {
        dispatch(closeCompleteModal())
        resetComplete()
    }
    const handleCompleteSubmit = (data) => {
        dispatch(compeleteActivation(data, editId, aggregatorId, searchData))
    }

    const handleRemarkModalOpen = (id) => {
        setFormButton('Submit');
        setFormTitle('Cancel Activation');
        seteditId(id);
        resetCancel();
        dispatch(prepareCancelModal());
        dispatch(getErrorCode());
    }
    const handleCancelRemark = () => {
        dispatch(closeCancelModal());
        resetCancel();
    }
    const handleCancelSubmit = (data) => {
        dispatch(cancelActivation(data, editId, aggregatorId, searchData))
    }

    const handleUnLockActivation = (activationID) => {
        const unLockData = {
            activationId: activationID,
        }
        dispatch(unLockActivation(unLockData, aggregatorId, searchData));
    }

    const handleTransactionIdModel = (item) => {
        setAggregatorID(item.AggregatorID)
        dispatch(transactionIdModal());
        dispatch(accessTransactionModal(item.ID));
    };
    const handleCloseTransectionIdModal = () => {
        dispatch(closeTransactionIdModal());
    };


    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetActivation());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetActivation());
    }

    //REQUEST INFORMATION
    const planIformationData = useMemo(() => {
        if (requestData && requestData.length > 0) {
            let addonData = requestData[0].AddOnServices.split(',').map((val) => { return val.split('_'); });
            addonData = removeSameElementFromArray(addonData);
            let addonHtml = addonData.map((addonItem) => {
                return <lable><CheckLineIcon color='green' />{addonItem[0]}&emsp; - &emsp;${addonItem[1]}<br /></lable>
            })
            if (requestData[0].NetworkName == "T-Mobile") {
                return [
                    {
                        name: "Plan Category",
                        value: requestData[0].PlanCategory,
                    },
                    {
                        name: "Plan Name",
                        value: requestData[0].PlanName,
                    },
                    {
                        name: "Plan Price",
                        value: '$' + requestData[0].PlanPrice,
                    },
                    {
                        name: "Plan Month",
                        value: requestData[0].PlanMonth + ' Months',
                    },
                    {
                        name: "Add On Services",
                        value: addonHtml,
                    },
                ]
            }
            else {
                return [
                    {
                        name: "Plan Name",
                        value: requestData[0].PlanName,
                    },
                    {
                        name: "Plan Price",
                        value: '$' + requestData[0].PlanPrice,
                    },
                    {
                        name: "Plan Month",
                        value: requestData[0].PlanMonth + ' Months',
                    },
                ]
            }
        } else {
            return [];
        }
    }, [requestData]);

    //CHILD DATA INFORMATION
    const childData = useMemo(() => {
        if (requestData && requestData.length > 0 && requestData[0].ChildActivation) {
            let childDataArr = JSON.parse(requestData[0].ChildActivation).map((item) => {
                return {
                    IMEI: item.IMEI,
                    Serial: item.SerialNumber,
                    SimType: item.SimType,
                    Mobile: item.MSISDN,
                    AddOnServices: '',
                    CallerID: ''
                }
            });
            return childDataArr;
        } else {
            return [];
        }
    }, [requestData]);

    return (
        <>
            {<>
                <CardLayout title="Activation Request">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">

                            <div className="col-lg-2 col-md-4 mb-2">
                                <label>Select Network</label>
                                <select className="form-control" id="networkID" name="networkID" {...registerFilter("networkID", {
                                })} >
                                    <option key={""} value="">All</option>
                                    {
                                        networkResult && networkResult.length > 0 && networkResult.filter((item) => item.IsActive == 1).map((item, index) => {
                                            return <option key={item.ID} value={item.ID}>{item.NetworkName}</option>
                                        })
                                    }
                                </select>
                            </div>


                            <div className="col-lg-2 col-md-4 mb-2">
                                <label>Select Status</label>
                                <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...registerFilter("statusID", {
                                })} >
                                    <option value="" name="All">All</option>
                                    <option key={'10'} value={'10'} name={'Processing'}>Pending</option>
                                    {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.filter(item => item.ID != 10).map((item, ind) => (
                                        <option key={item.ID} value={item.ID} name={item.Value}>{item.Value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-4  mb-2">
                                <label>Select Dealer</label>
                                <select className="form-control" id="dealerID" name="dealerID"  {...registerFilter("dealerID", {
                                })} >
                                    <option value="" name="All">All</option>
                                    {
                                        dealerResult && dealerResult.length && dealerResult.map((item) => {
                                            return <option value={item.ID} key={`dealer${item.ID}`}>{item.DealerName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                <label>From Date</label>
                                <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                <DatePicker
                                    ref={datePickerRef}
                                    selected={fromDate}
                                    className="form-control"
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    name="fromDate"
                                    showMonthDropdown
                                    showYearDropdown
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    value={fromDate}
                                />
                            </div>

                            <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                <label>To Date</label>
                                <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                <DatePicker
                                    ref={datePickerRef2}
                                    selected={toDate}
                                    className="form-control center"
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    name="todate"
                                    showMonthDropdown
                                    showYearDropdown
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    value={toDate}
                                />
                            </div>

                            <div className="col-lg-2 col-md-4 mb-2">
                                <label>IMEI/SIM/Mobile/TXN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="serialNumber"
                                    name="serialNumber"
                                    placeholder="IMEI/SIM/Mobile/TXN"
                                    {...registerFilter("serialNumber")}
                                />
                            </div>

                            <div className={ 'col-lg-12 mb-2'}>
                                <div className={ "mt-2 d-flex justify-content-end activation-search-btn"}>
                                    <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                                </div>
                            </div>

                        </div>
                    </form>
                    <hr />

                    <div className="row mb-3 " >
                        <div className='col-lg-3  activation-request-search-btn mb-sm-444'>
                            <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                        </div>
                    </div>
                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                    <div id={'activationRequestTable'}>
                        <Table columns={columns} pagination={true} loading={activationListLoading} data={data} />
                    </div>

                    {/* Transection View modal */}
                    <Modal
                        showModal={transectionIdModal}
                        title={`CHANNEL TXN ID: ${requestData && requestData.length > 0 ? requestData[0].TransactionID : ""}`}
                        onHide={handleCloseTransectionIdModal}
                        width={750}
                        showFooter={false}
                    >
                        {
                            modalViewLoader ? <>
                                <div className='container text-center'>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </> :
                                <>
                                    <div className="row text-dark px-4">
                                        <div className="col-md-12">
                                            <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> REQUEST INFORMATION</h5>
                                            <hr />
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>IMEI</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].IMEI ? requestData[0].IMEI : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>SIM Type</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].SimType ? requestData[0].SimType : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Serial #</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].SerialNumber ? requestData[0].SerialNumber : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Zip Code</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].AreaCode ? requestData[0].AreaCode : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Activation Type</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].RequestType ? requestData[0].RequestType : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Mobile #</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].MSISDN ? requestData[0].MSISDN : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Account #</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].AccountNumber ? requestData[0].AccountNumber : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>Order #</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].OrderNumber ? requestData[0].OrderNumber : "N/A"}</span>
                                        </div>

                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>T-Mobile PIN</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].PIN ? requestData[0].PIN : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                            <span>WiFi Calling </span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].WiFiCalling ? 'Yes' : 'No'}</span>
                                        </div>
                                        <div className="col-md-8 d-flex justify-content-between mb-2">
                                            <span>Customer Email</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].CustomerEmail ? requestData[0].CustomerEmail : "N/A"}</span>
                                        </div>
                                    </div>

                                    {requestData && requestData.length > 0 && requestData[0].Multiline === 1 &&
                                        <div>
                                            <hr className="mx-4" />
                                            <h5 className="col-md-12 px-4" style={{ fontWeight: "700" }}>CHILD ACTIVATION DATA</h5>
                                            <hr className="mx-4 mb-0" />
                                            {childData && childData.length > 0 && childData.map((item, index) => (
                                                <fieldset className="border">
                                                    <legend> <span>Child {index + 2}:</span></legend>
                                                    <div className="row text-dark">
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>IMEI</span>
                                                            <span>{item.IMEI}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>Serial</span>
                                                            <span>{item.Serial}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>SIM Type</span>
                                                            <span>{item.SimType}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>Mobile</span>
                                                            <span>{item.Mobile != '' && item.Mobile ? item.Mobile : 'N/A'}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>Add On Services</span>
                                                            <span>{item.AddOnServices != '' ? item.AddOnServices : 'N/A'}</span>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-between mb-2">
                                                            <span>CallerID</span>
                                                            <span>{item.CallerID != '' ? item.CallerID : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            ))}
                                        </div>}


                                    {aggregatorID == 2 ? <div className="row text-dark px-4">
                                        <div className="col-md-12">
                                            <hr />
                                            <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> DEALER INFORMATION </h5>
                                            <hr />
                                        </div>
                                        <div className="col-md-12  d-flex justify-content-between mb-2">
                                            <span>Dealer Name</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].DealerName ? requestData[0].DealerName : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6  d-flex justify-content-between mb-2">
                                            <span>TMobile Username</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].TMobileUsername ? requestData[0].TMobileUsername : "N/A"}</span>
                                        </div>
                                        <div className="col-md-6  d-flex justify-content-between mb-2">
                                            <span>TMobile Password</span>
                                            <span className="text-end">{requestData && requestData.length > 0 && requestData[0].TMobilePassword ? requestData[0].TMobilePassword : "N/A"}</span>
                                        </div>

                                    </div> : ''}



                                    <div className="row text-dark px-4">
                                        <div className="col-md-12">
                                            <hr />
                                            <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}>
                                                PLAN INFORMATION
                                            </h5>
                                            <hr />
                                        </div>
                                        {planIformationData && planIformationData.length > 0 &&
                                            planIformationData.map((item, index) => (
                                                <div className={index == 0 || (index == 1 && item.name != "Plan Price") || index == 4 ? `col-md-12 d-flex justify-content-between mb-2` : `col-md-6 d-flex justify-content-between mb-2`}>
                                                    <span>{item.name}</span>
                                                    <span className="text-end">{item.value}</span>
                                                </div>
                                            ))}
                                    </div>

                                    <hr className="mx-4" />

                                    {aggregatorID == 2 ? <div className="row text-dark px-4">
                                        <div className="col-md-12">
                                            <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> REQUEST JOURNEY </h5>
                                            <hr />
                                            {
                                                requestData && requestData.length > 0 && requestData[0].logHistory && requestData[0].logHistory.length > 0 && requestData[0].logHistory.map((item) => {
                                                    let note = JSON.parse(item.Note);
                                                    return <>
                                                        <div className="row">
                                                            <div className='col-lg-4'>
                                                                <ul className={`${Styles['sessions-mvo']} p-0`}>
                                                                    <li className={item.IsError == 1 ? `${Styles['timemvoline']} ${Styles['red']}` : `${Styles['timemvoline']} ${Styles['green']}`}>
                                                                        <div className={Styles['time-mvo']}>{moment(item.CreatedDate).format(LONG_TIME_FORMAT)}</div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className='col-lg-8'>
                                                                <ul className='p-0'>
                                                                    <li className=' align-items-baseline'>
                                                                        <div className='d-flex'>
                                                                            <span className='mr-1'><i className={item.IsError === 0 ? 'ri-checkbox-circle-line' : 'ri-close-circle-line'} style={{ fontSize: '19px', color: item.IsError === 0 ? '#1ee0ac' : '#e74c3c' }}></i>
                                                                            </span>
                                                                            <h6 className={Styles['timeline-titlemvno']}>{item.IsError === 0 ? (item.Note.charAt(0) != '{' ? item.Note : JSON.parse(item.Note).Value !== '' ? JSON.parse(item.Note).Value : null) : JSON.parse(item.Note).jarAssetName != undefined ? item.ErrorDesc + ' (' + JSON.parse(item.Note).jarAssetName + ')' : item.ErrorDesc} {item.Note.charAt(0) != '{' ? '' : JSON.parse(item.Note).RequestStatus !== "" ? <span className='mx-2 font-weight-normal' style={{ color: "#8094ae" }}>{JSON.parse(item.Note).RequestStatus === 18 ? '(' + JSON.parse(item.Note).MSISDN + ')' : JSON.parse(item.Note).RequestStatus === 12 ? '(' + item.OrderNumber + ')' : null}</span> : null}</h6>
                                                                        </div>

                                                                        {item.IsError === 1 ? <p className='ml-3' style={{ color: "#8094ae" }}>{JSON.parse(item.Note).ErrorDesc}</p> : ''}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </>
                                                })
                                            }

                                        </div>
                                    </div> : ''}
                                </>
                        }
                    </Modal>

                    {/* cancel activation */}
                    <Modal
                        showModal={cancelModal}
                        title={formTitle}
                        onHide={handleCancelRemark}
                        width={700}
                        showFooter={false}
                    >
                        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                        <form onSubmit={handleCancelActivation(handleCancelSubmit)}>
                            <div className="form-row">
                                <div className="col-md-12 col-lg-12 mb-2">
                                    <label>Error</label>
                                    <select className="form-control" id="errorCode" name="errorCode" placeholder="Error"  {...registerCancel("errorCode", {
                                        required: true
                                    })} >
                                        <option value="" name="All">Select</option>
                                        {errorCodeResult && errorCodeResult.length > 0 && errorCodeResult.map((item => (
                                            <option value={item.ErrorCode} name="All">{item.ErrorDesc}</option>
                                        )))}

                                    </select>
                                    {cancelError.errorCode?.type === "required" && (
                                        <div className="invalid-feedback">Select Error !</div>
                                    )}
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label>Remark</label>
                                    <textarea className="form-control" placeholder="Enter Remark" id="remark" name="remark" rows="3" cols="30" {...registerCancel("remark", {
                                        required: true
                                    })}>
                                    </textarea>
                                    {cancelError.remark?.type === "required" && (
                                        <div className="invalid-feedback">Remark required !</div>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div className='text-right row'>
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelRemark}>Close</button>
                                    <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                </div>
                            </div>
                        </form>
                    </Modal>

                    {/* complete activation */}
                    <Modal
                        showModal={completeModal}
                        title={formTitle}
                        onHide={handleCancelComplete}
                        width={700}
                        showFooter={false}
                    >
                        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                        <form onSubmit={handleCompleteActivation(handleCompleteSubmit)}>
                            <div className="form-row">
                                {simTypeID==26 ? <div className="col-md-12 mb-3">
                                    <label>EID</label>
                                    <input type="number" placeholder="Enter EID" className="form-control"
                                        disabled={disableEID}
                                        name="eId" {...registerComplete("eId", {
                                            required: !disableEID,
                                        })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                    {completeError.eId?.type === "required" && (
                                        <div className="invalid-feedback">EID  required !</div>

                                    )}
                                </div> :''}
                                <div className="col-md-6 mb-3">
                                    <label>Mobile Number</label>
                                    <input type="number" className="form-control" placeholder="Enter Mobile Number" name="MSISDN"
                                        disabled={mobileDisable}
                                        {...registerComplete("MSISDN", {
                                            required: !mobileDisable,
                                        })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                    {completeError.MSISDN?.type === "required" && (
                                        <div className="invalid-feedback">Mobile number is required !</div>
                                    )}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Account Number</label>
                                    <input type="number" className="form-control" placeholder="Enter Account Number" name="accountNumber" {...registerComplete("accountNumber", {
                                        required: true,
                                    })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                    {completeError.accountNumber?.type === "required" && (
                                        <div className="invalid-feedback">Account number is required !</div>
                                    )}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Order Number</label>
                                    <input type="number" className="form-control" placeholder="Enter Order Number" name="orderNumber" {...registerComplete("orderNumber", {
                                        required: true,
                                    })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                    {completeError.orderNumber?.type === "required" && (
                                        <div className="invalid-feedback">Order number is required !</div>
                                    )}
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Transaction ID</label>
                                    <input type="number" className="form-control" placeholder="Enter Transaction ID Number" name="transactionId" {...registerComplete("transactionId", {
                                        required: true,
                                    })} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                    {completeError.transactionId?.type === "required" && (
                                        <div className="invalid-feedback"> Transaction ID is required !</div>
                                    )}
                                </div>

                            </div>

                            <hr />
                            <div className='text-right row'>
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-secondary mr-2" onClick={handleCancelComplete}>Close</button>
                                    <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                </div>
                            </div>
                        </form>
                    </Modal>
                </CardLayout>
            </>
            }
        </>
    )
}

const styles = {
    multilineActivation: {
        color: "#e74c3c",
        border: "1px solid",
        padding: "3px 5px",
        borderRadius: "9px"
    }
}

export default ActivationRequest;